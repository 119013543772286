import {defineStore} from 'pinia'
import {useGetPartnerPortalData} from '@/uses/Partner/useGetPartnerPortalData'
import {computed, watchEffect} from 'vue'
import useMeta from 'quasar/src/composables/use-meta.js';
import {useEnvStore} from '@/stores/useEnvStore'

export const usePartnerStore = defineStore(
  'partner',
  () => {
    const {
      loading,
      data,
      status,
      load,
    } = useGetPartnerPortalData()

    const loaded = computed(() => {
      /*if (window.location.hostname.includes('sign.gosoblako.com')
        //|| window.location.hostname.includes('sign.localfenixfin.ru')
      ) {
        return true
      }*/

      return !!data.value && status.value !== 404
    })

    const enabledStyles = computed(() => {
      if (!data.value) {
        return false
      }

      return loaded.value && data.value.enabled
    })

    const agentCode = computed(() => {
      if (!data.value) {
        return undefined
      }

      return data.value.agent_code
    })

    const styles = computed(() => {
      if (!data.value || !data.value.enabled) {
        return {
          '--btn_text_color': 'white',
          '--btn_background_color': '--q-primary',
          '--input_text_color': 'rgba(0, 0, 0, 0.6)',
          '--input_text_border': 'rgba(0, 0, 0, 0.6)',
          '--input_background_color': '#eceff1',
          '--home_page_background_color': '#263238',
          '--other_page_background_color': '#263238',
          '--home_page_background_dark_color': '#263238',
          '--logo_placeholder_color': 'white',
          '--home_page_background_image': 'url(/main.jpg)',
          '--other_page_background_image': 'url(/main.jpg)',
        }
      }

      return {
        '--btn_text_color': data.value.design_config.btn_text_color || 'white',
        '--btn_background_color': data.value.design_config.btn_background_color || '#027BE3',
        '--input_text_color': data.value.design_config.input_text_color || 'rgba(0, 0, 0, 0.6)',
        '--input_text_border': hexToRgba(data.value.design_config.input_text_color) || 'rgba(0, 0, 0, 0.3)',
        '--input_background_color': data.value.design_config.input_background_color || '#eceff1',
        '--home_page_background_color': data.value.design_config.home_page_background_color || '#263238',
        '--home_page_background_dark_color': newShade(data.value.design_config.home_page_background_color, -10) || '#263238',
        '--other_page_background_color': data.value.design_config.other_page_background_color || '#263238',
        '--logo_placeholder_color': data.value.design_config.logo_placeholder_color || 'white',
        '--home_page_background_image': `url(${data.value.design_config.home_page_background_image || '/main.jpg'})`,
        '--other_page_background_image': `url(${data.value.design_config.other_page_background_image || '/main.jpg'})`,
        '--banner_btn_background_color': data.value?.banner.btn_background_color || '#027BE3',
        '--banner_btn_text_color': data.value?.banner.btn_text_color || 'white',
        '--banner_background_color': data.value?.banner.background_color || '#f6c1c1',
      }
    })

    const logoUrl = computed(() => {
      if (!data.value || !data.value.enabled || !data.value.design_config.logo) {
        return '/main_logo.svg'
      }

      return data.value.design_config.logo
    })

    const logoInnerUrl = computed(() => {
      if (!data.value || !data.value.enabled || !data.value.design_config.logo) {
        return '/logo.svg'
      }

      return data.value.design_config.logo
    })

    const hasLogoImage = computed(() => {
      if (!data.value || !data.value.enabled) {
        return true
      }

      return !!data.value.design_config.logo
    })

    const phone = computed(() => {
      if (enabledStyles.value && data.value) {
        return data.value.design_config.support_phone
      }

      return '+7 (499) 110 16 42'
    })

    const email = computed(() => {
      if (enabledStyles.value && data.value) {
        return data.value.design_config.support_email
      }

      return 'support@gosoblako.com'
    })

    const setMeta = () => {
      let link = document.querySelector<HTMLLinkElement>('link[rel~=\'icon\']')
      if (!link) {
        link = document.createElement('link')
        document.head.appendChild(link)
        link.rel = 'icon'
      }
      if (data.value && data.value.enabled) {
        link.href = '/default-partner-icon.ico'
        useMeta(() => {
          return {
            title: 'Подписание КЭП'
          }
        })

        return
      }

      link.href = '/favicon.ico'
      useMeta(() => {
        return {
          title: 'GOSOBLAKO'
        }
      })
    }

    const hexToRgba = (hex: string) => {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

      return result
        ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, 0.2)`
        : undefined
    }

    const newShade = (hexColor: string, magnitude: number) => {
      hexColor = (hexColor || '').replace(`#`, ``)
      if (hexColor.length === 6) {
        const decimalColor = parseInt(hexColor, 16)
        let r = (decimalColor >> 16) + magnitude
        r > 255 && (r = 255)
        r < 0 && (r = 0)
        let g = (decimalColor & 0x0000ff) + magnitude
        g > 255 && (g = 255)
        g < 0 && (g = 0)
        let b = ((decimalColor >> 8) & 0x00ff) + magnitude
        b > 255 && (b = 255)
        b < 0 && (b = 0)

        return `#${(g | (b << 8) | (r << 16)).toString(16)}`
      } else {
        return hexColor
      }
    }

    watchEffect(() => {
      Object.entries(styles.value)
        .forEach(([name, value]) => {
          document.documentElement.style.setProperty(name, value)
        })
      document.body.classList.remove('partnerPortalMode')
      document.body.classList.remove('btn_text_color')
      document.body.classList.remove('btn_background_color')
      document.body.classList.remove('input_text_color')
      document.body.classList.remove('input_text_border')
      document.body.classList.remove('input_background_color')
      document.body.classList.remove('other_page_background_color')
      document.body.classList.remove('home_page_background_color')
      document.body.classList.remove('logo_placeholder_color')
      document.body.classList.remove('other_page_background_image')
      document.body.classList.remove('home_page_background_image')

      if (status.value) {
        setMeta()
      }

      if (data.value && !enabledStyles.value && data.value.agent_code) {
        const envStore = useEnvStore()
        if (window.location.hostname.includes('sign.gosoblako.com')) {
          return
        }
        window.location.href = `https://${data.value.agent_code}${envStore.editable ? '.pre' : ''}.sign.gosoblako.com/`

        return
      }

      if (!enabledStyles.value || !data.value) {
        return
      }

      document.body.classList.add('partnerPortalMode')
      //const color = data.value.design_config.home_page_background_color || '#000606'
      //const image = data.value.design_config.home_page_background_image || '/main.jpg'
      //document.documentElement.style.backgroundColor = `${color}`
      //document.documentElement.style.backgroundImage = `url('${image}')`

      if (data.value.design_config.btn_text_color) {
        document.body.classList.add('btn_text_color')
      }
      if (data.value.design_config.btn_background_color) {
        document.body.classList.add('btn_background_color')
      }
      if (data.value.design_config.input_text_color) {
        document.body.classList.add('input_text_color')
      }
      if (data.value.design_config.input_text_color) {
        document.body.classList.add('input_text_border')
      }
      if (data.value.design_config.input_background_color) {
        document.body.classList.add('input_background_color')
      }
      if (data.value.design_config.other_page_background_color) {
        document.body.classList.add('other_page_background_color')
      }
      if (data.value.design_config.home_page_background_color) {
        document.body.classList.add('home_page_background_color')
      }
      if (data.value.design_config.logo_placeholder_color) {
        document.body.classList.add('logo_placeholder_color')
      }
      if (data.value.design_config.other_page_background_image) {
        document.body.classList.add('other_page_background_image')
      }
      if (data.value.design_config.home_page_background_image) {
        document.body.classList.add('home_page_background_image')
      }
    })

    return {
      loading,
      loaded,
      styles,
      logoUrl,
      logoInnerUrl,
      hasLogoImage,
      data,
      enabledStyles,
      agentCode,
      phone,
      email,
      status,
      load,
    }
  })

import {RouterGuard} from '@/models/Router/RouterGuard'
import {useAuthStore} from '@/stores/Login/useAuthStore'
import {UserRoleEnum} from '@/models/User/UserRoleEnum'

const canAccess = () => {
  const authStore = useAuthStore()

  return !!authStore.profile
    && [
      UserRoleEnum.Client,
    ].includes(authStore.profile.role)
}

const redirectTo = () => {
  if (canAccess()) {
    return undefined
  }

  return {
    name: 'auth',
  }
}

export const ApplicationsGridGuard: RouterGuard = {
  canAccess,
  redirectTo,
}

import {ref, watchEffect} from 'vue'
import {useLoading} from '../useLoading'
import axios, {AxiosError, AxiosInstance, AxiosRequestHeaders, AxiosResponseHeaders, Method, ResponseType} from 'axios'
import {useAuthStore} from '@/stores/Login/useAuthStore'
import {BackendError, BackendErrorTypeEnum} from '@/models/BackendError'
import {useNotifyErrorsStore} from '@/stores/useNotifyErrorsStore'
import router from "@/router";
import {useTokenStore} from '@/stores/Login/useTokenStore'
import {EnvCodeEnum, useEnvStore} from '@/stores/useEnvStore'
import {storeToRefs} from 'pinia'

export const useRequestApi = <T = never>(showErrors = false) => {
  const {
    getEnvValue,
  } = useEnvStore()
  const {
    env,
  } = storeToRefs(useEnvStore())

  const authStore = useAuthStore()
  const {loading, startLoading, stopLoading} = useLoading()
  const response = ref<T>()
  const responseHeaders = ref<AxiosResponseHeaders>()
  const error = ref<string>()
  const errors = ref<Record<string, string>>()
  const status = ref<number>()

  let controller: AbortController
  const abort = () => {
    if (!controller) {
      return
    }
    controller.abort()
  }

  const createInstance = () => {
    abort()
    return axios.create({
      baseURL: `${getEnvValue(EnvCodeEnum.VUE_APP_API_URL)}/api/`,
      headers: {
        Accept: 'application/json',
      }
    })
  }

  const axiosInstance = ref<AxiosInstance>(createInstance())

  const {
    logout,
  } = useTokenStore()

  const useNotifyErrors = useNotifyErrorsStore()
  const {
    addErrorMessage
  } = useNotifyErrors

  const clear = () => {
    status.value = undefined
    response.value = undefined
    error.value = undefined
    errors.value = undefined
  }

  const request = async <R = never>(url: string, method: Method, data?: R, type: ResponseType = 'json') => {
    abort()
    controller = new AbortController()
    clear()
    startLoading()
    const requestHeaders: AxiosRequestHeaders = {}
    if (authStore.token) {
      requestHeaders.authorization = `Bearer ${authStore.token}`
    }
    try {
      const {
        data: responseData,
        status: responseStatus,
        headers
      } = await axiosInstance.value.request<T>({
        url,
        method,
        data,
        headers: requestHeaders,
        signal: controller.signal,
        responseType: type,
      })
      response.value = responseData
      status.value = responseStatus
      responseHeaders.value = headers
      if (responseStatus === 401) {
        await logout()
        await router.push({name: 'auth'})
      }
      stopLoading()
    } catch (e) {
      processError(e as unknown as AxiosError)
    }
  }

  const processError = async (e: Error | AxiosError) => {
    console.log(e)
    if (axios.isAxiosError(e)) {
      if (e.code === 'ERR_CANCELED') {
        return
      }

      status.value = e.response?.status || 500

      if (status.value === 401) {
        await logout()
        await router.push({name: 'auth'})

        return
      }

      const data = e.response?.data as BackendError

      if (data?.error_type === undefined) {
        error.value = data.message
      }

      if ((data?.error_type || '') === BackendErrorTypeEnum.validation
        && data?.errors && Object.keys(data.errors).length
      ) {
        errors.value = data.errors
        stopLoading()

        if (showErrors) {
          showNotifyValidationError(data.errors)
        }

        return
      }

      if ((data?.error_type || '') === BackendErrorTypeEnum.exception) {
        error.value = data.message
        //addErrorMessage(error.value)
      }
      stopLoading()

      return
    }

    error.value = (e as Error).message || ''
  }
  stopLoading()

  const showNotifyValidationError = (errors: Record<string, string>) => {
    for (const error in errors) {
      const message = `Ошибка валидации! Поле ${error}: ${errors[error]}`
      addErrorMessage(message)
    }
  }

  watchEffect(() => {
    if (!env.value) {
      return
    }
    axiosInstance.value = createInstance()
  })

  return {
    response,
    responseHeaders,
    error,
    errors,
    status,
    loading,
    request,
    abort
  }
}

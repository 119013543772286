import './styles/quasar.scss'
import lang from 'quasar/lang/ru.js'
import '@quasar/extras/material-icons/material-icons.css'
import Notify from 'quasar/src/plugins/Notify.js';import Meta from 'quasar/src/plugins/Meta.js';

// To be used on app.use(Quasar, { ... })
export default {
  config: {
    animations: 'all',
    notify: { /* look at QuasarConfOptions from the API card */ }
  },
  plugins: {
    Notify,
    Meta,
  },
  lang: lang
}

import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import Quasar from 'quasar/src/vue-plugin.js';
import {createPinia} from 'pinia'
import i18n from '@/i18n'
// eslint-disable-next-line
// @ts-ignore
import { resetStore } from './plugins/pinia/reset-store.js'

import { Money3Directive } from 'v-money3'

// eslint-disable-next-line
// @ts-ignore
import quasarUserOptions from './quasar-user-options.js'
// eslint-disable-next-line
// @ts-ignore
import postHogPlugin from './plugins/posthog.js'
const pinia = createPinia()
pinia.use(resetStore)

const vueInstance = createApp(App)
  .use(i18n)
  .use(
    Quasar,
    quasarUserOptions
  )
  .use(router)
  .use(pinia)
  .use(postHogPlugin)
  .directive('money3', Money3Directive)
  .mount('#app')

export default vueInstance

import {useRequestApi} from './useRequestApi'

export const useGetApi = <T = never>(showErrors = false) => {
  const {
    response,
    error,
    errors,
    status,
    loading,
    request
  } = useRequestApi<T>(showErrors)

  const get = async (url: string, filters = {}) => {
    const query = Object.entries<string>(filters)
      .map(entry => {
        if (Array.isArray(entry[1])) {
          return entry[1].map((item) => `${entry[0]}[]=${item}`)
            .join('&')
        }
        return `${entry[0]}=${entry[1]}`
      })
    const queryString = query.length > 0 ? `?${query.join('&')}` : ''

    await request(`${url}${queryString}`, 'GET')
  }

  return {response, error, errors, status, loading, get}
}

import {defineStore} from 'pinia'
import {WindowWithYA} from '@/models/WindowWithYa'
import {computed, ref} from 'vue'
import {UserProfile} from '@/models/User/UserProfile'

export const useYaMetrica = defineStore('ya-metrica-store', () => {
  const metrica = ref<WindowWithYA>()
  const counter = computed(() => {
    if (!metrica.value) {
      return undefined
    }

    return Object.values(metrica.value.Ya._metrika.counters)[0] || undefined
  })

  const onProfile = (profile: UserProfile) => {
    if (!counter.value) {
      return
    }

    if (counter.value) {
      counter.value.setUserID(profile.id);
      counter.value.userParams({
        user_role: 'client',
        client_name: profile.title,
        client_inn: profile.inn,
        UserID: profile.id
      });
    }
  }

  metrica.value = (window as never as WindowWithYA) || undefined

  return {
    onProfile,
    counter,
  }
})

import {RouteLocationNormalizedLoaded, RouteLocationRaw} from 'vue-router'
import {RouterGuard} from '@/models/Router/RouterGuard'
import {GuestGuard} from '@/router/quards/GuestGuard'

export const useAccess = (
  to: RouteLocationNormalizedLoaded,
  from: RouteLocationNormalizedLoaded,
) => {
  const getGuards = (): RouterGuard[]  => {
    return (to?.meta['guards'] as never as RouterGuard[] | undefined) || [GuestGuard]
  }

  const canAccess = () => {
    for (const guard of getGuards()) {
      if (guard.canAccess(from)) {
        continue
      }

      return false
    }

    return true
  }

  const redirectTo = (): RouteLocationRaw => {
    for (const guard of getGuards()) {
      if (guard.canAccess(from)) {
        continue
      }

      const redirectTo = guard.redirectTo(from)
      if (!redirectTo) {
        return to
      }

      return redirectTo
    }

    return to
  }

  return {
    canAccess,
    redirectTo,
  }
}

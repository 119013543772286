import {ApplicationsGridGuard} from '@/router/quards/ApplicationsGridGuard'
import {AuthedGuard} from '@/router/quards/AuthedGuard'

export const applicationsRouter = {
  path: '/applications',
  name: 'applications',
  component: () => import(/* webpackChunkName: "MainLayout" */ '../layouts/MainLayout.vue'),
  children: [
    {
      path: 'grid',
      name: 'applications-grid',
      component: () => import(/* webpackChunkName: "ApplicationsGridView" */ '../views/Applications/ApplicationsGridView.vue'),
      meta: {
        guards: [
          AuthedGuard,
          ApplicationsGridGuard,
        ],
      },
    },
  ]
}

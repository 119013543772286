export enum UserRoleEnum {
  Admin = 'ROLE_ADMIN',
  JuniorAdmin  = 'ROLE_JUNIOR_ADMIN',
  MiddleAdmin  = 'ROLE_MIDDLE_ADMIN',
  Agent = 'ROLE_AGENT',
  User = 'ROLE_USER',
  HeadEmployee = 'ROLE_HEAD_EMPLOYEE',
  Employee = 'ROLE_EMPLOYEE',
  HeadOperator = 'ROLE_HEAD_OPERATOR',
  Operator = 'ROLE_OPERATOR',
  AgentManager = 'ROLE_AGENT_MANAGER',
  RegionalManager = 'ROLE_REGIONAL_MANAGER',
  Client = 'ROLE_CLIENT',
}

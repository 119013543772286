import {defineStore, storeToRefs} from 'pinia'
import {useTokenStore} from '@/stores/Login/useTokenStore'
import {ref, watch} from 'vue'
import {useGetApi} from '@/uses/Api/useGetApi'
import {UserProfile, UserProfileClient} from '@/models/User/UserProfile'
import router from '@/router'
import {useYaMetrica} from '@/stores/useYaMetrica'

export const useProfileStore = defineStore('profile', () => {
  const tokenStore = useTokenStore()
  const {tokenData} = storeToRefs(tokenStore)
  const profile = ref<UserProfile>()
  const selectedClient = ref<UserProfileClient>()
  const {response, status, get, loading} = useGetApi<UserProfile>()
  const yaMetrica = useYaMetrica()

  const setClientToRouter = async () => {
    if (!selectedClient.value) {
      return
    }

    if (!router.currentRoute.value.name || !router.currentRoute.value.query) {
      return
    }

    await router.push({
      name: router.currentRoute.value.name,
      query: {
        ...(router.currentRoute.value.query || {}),
        client: selectedClient.value.id
      }
    })
  }

  watch(
    router.currentRoute,
    () => {
      if (!profile.value || !profile.value.clients) {
        return
      }

      if (!router.currentRoute.value.query || !router.currentRoute.value.query.client) {
        return
      }

      selectedClient.value = profile.value.clients
        .find(client => client.id === router.currentRoute.value.query.client)
    }
  )

  watch(
    tokenStore.$state,
    async () => {
      if (!tokenData.value) {
        return
      }

      await get('client/v1/profile')
      if (status.value !== 200 || !response.value) {
        await router.push({name: 'auth'})
      }

      profile.value = response.value
      if (profile.value) {
        selectedClient.value = profile.value?.clients[0] || undefined
        yaMetrica.onProfile(profile.value)
      }
    },
    {deep: true}
  )

  watch(selectedClient, setClientToRouter)

  return {
    profile,
    loading,
    selectedClient,
    setClientToRouter,
  }
})

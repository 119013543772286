import {useGetApi} from '@/uses/Api/useGetApi'
import {ref} from 'vue'
import {AgentPortalData} from '@/models/Portal/AgentPortalData'

export const useGetPartnerPortalData = () => {
  const {
    loading,
    get,
    status,
    response,
  } = useGetApi<AgentPortalData>()

  const data = ref<AgentPortalData>()

  const load = async () => {
    const host = document.location.hostname
    await get(
      `client/v1/sign-portals/${host}`,
    )

    if (status.value === 200 && response && response.value) {
      data.value = response.value
    }
  }

  return {
    loading,
    data,
    status,
    load,
  }
}

import {RouterGuard} from '@/models/Router/RouterGuard'
import {useAuthStore} from '@/stores/Login/useAuthStore'

export const AuthedGuard: RouterGuard = {
  canAccess: () => {
    const authStore = useAuthStore()

    return authStore.isAuthed
  },
  redirectTo: () => {
    const authStore = useAuthStore()
    if (authStore.isAuthed) {
      return undefined
    }

    return {
      name: 'auth',
    }
  }
}

import {defineStore} from 'pinia'
import {ref} from 'vue'
import Notify from 'quasar/src/plugins/Notify.js';

export const useNotifyErrorsStore = defineStore('notify-errors-store', () => {

  const notifyMessage = ref<string | undefined>('')
  const position = ref<
    | "top-left"
    | "top-right"
    | "bottom-left"
    | "bottom-right"
    | "top"
    | "bottom"
    | "left"
    | "right"
    | "center"
    | undefined
    >('top')
  const type = ref<string>('negative')
  const timeout = ref<number>(4500)
  const textColor = ref<string>('white')

  const showNotify = () => {
    Notify.create({
      type: type.value,
      message: notifyMessage.value,
      position: position.value,
      timeout: timeout.value,
      textColor: textColor.value,
      actions: [
        { label: 'Ок', color: 'white', handler: () => {
            notifyMessage.value = ''
          } }
      ]
    })
  }

  const addErrorMessage = (message: string) => {
    notifyMessage.value = message
    showNotify()
  }

  return {
    addErrorMessage,
    notifyMessage,
    position,
    type,
    timeout,
    textColor
  }
})

import {defineStore} from 'pinia'
import {useLocalStorage} from '@/uses/useLocalStorage'
import {computed, ref, watchEffect} from 'vue'

export enum EnvCodeEnum {
  VUE_APP_ENV_EDITABLE = 'VUE_APP_ENV_EDITABLE',
  VUE_APP_I18N_LOCALE = 'VUE_APP_I18N_LOCALE',
  VUE_APP_I18N_FALLBACK_LOCALE = 'VUE_APP_I18N_FALLBACK_LOCALE',
  VUE_APP_API_URL = 'VUE_APP_API_URL',
  VUE_APP_ZAKUPKI_URL = 'VUE_APP_ZAKUPKI_URL',
  VUE_APP_AGENT_CODE = 'VUE_APP_AGENT_CODE',
}

export const protectedEnv = [
  EnvCodeEnum.VUE_APP_I18N_LOCALE,
  EnvCodeEnum.VUE_APP_I18N_FALLBACK_LOCALE,
  EnvCodeEnum.VUE_APP_ENV_EDITABLE,
]

export const useEnvStore = defineStore(
  'env-store',
  () => {
    const {
      data,
      getFromStorage,
      setToStorage,
    } = useLocalStorage<Record<EnvCodeEnum, string>>('env')

    const env = ref<Record<EnvCodeEnum, string>>()

    const editable = computed(() => {
      if (!env.value) {
        return false
      }

      return getEnvValue(EnvCodeEnum.VUE_APP_ENV_EDITABLE) === 'true'
    })

    const getEnvValue = (code: EnvCodeEnum): undefined | string => {
      if (!env.value) {
        return undefined
      }

      return env.value[code]
    }

    const isEditable = (code: EnvCodeEnum) => {
      return env.value && editable.value && !protectedEnv.includes(code)
    }

    const setValue = (code: EnvCodeEnum, value: string) => {
      if (!isEditable(code) || !env.value) {
        return
      }

      env.value[code] = value
    }

    const setToDefault = () => {
      env.value = Object.fromEntries(
        Object.values(EnvCodeEnum)
          .map(code => {
            return [code, process.env[code] ? process.env[code] as string : '']
          })
      ) as Record<EnvCodeEnum, string>
    }

    const init = () => {
      getFromStorage()
      env.value = Object.fromEntries(
        Object.values(EnvCodeEnum)
          .map(code => {
            const value = !protectedEnv.includes(code) && data.value && data.value[code]
              ? data.value[code]
              : process.env[code] as string

            return [code, value]
          })
      ) as Record<EnvCodeEnum, string>
    }

    init()

    watchEffect(() => {
      if (!env.value) {
        return
      }

      setToStorage(env.value)
    })

    return {
      editable,
      env,
      getEnvValue,
      setValue,
      setToDefault,
      isEditable,
    }
  }
)

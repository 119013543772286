import {defineStore} from 'pinia'
import {computed, ref} from 'vue'
import {LoginResponseData} from '@/models/User/Login/LoginResponseData'
import moment from 'moment'
import router from '@/router'
import {useLocalStorage} from '@/uses/useLocalStorage'

export const useTokenStore = defineStore('token', () => {
  const {data, getFromStorage, setToStorage} = useLocalStorage<LoginResponseData>('token')
  const tokenData = ref<LoginResponseData | null>(null)

  const logout = () => {
    tokenData.value = null
    router.push({name: 'auth'})
  }

  const setToken = (data: LoginResponseData) => {
    const dataToSet = {
      ...data,
      expired: Number(data?.expired),
    }

    if (!dataToSet?.dateTime) {
      dataToSet.dateTime = moment()
    }

    tokenData.value = dataToSet
    setToStorage(tokenData.value)
  }


  const token = computed(() => tokenData.value?.token)

  const updateFromStorage = () => {
    getFromStorage()
    if (!data.value) {
      return
    }

    setToken({
      ...data.value,
      dateTime: moment(data.value?.dateTime || undefined)
    })
  }

  updateFromStorage()
  setTimeout(updateFromStorage, 0)

  return {setToken, tokenData, token, logout}
})

import {createRouter, createWebHashHistory, RouteRecordRaw} from 'vue-router'
import {applicationsRouter} from '@/router/applications'
import {useAccess} from '@/uses/useAccess'
import {useAuthStore} from '@/stores/Login/useAuthStore'
import {usePartnerStore} from '@/stores/usePartnerStore'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'auth',
    component: () => import(/* webpackChunkName: "AuthLayout" */ '../layouts/AuthLayout.vue'),
  },
  applicationsRouter,
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  const partnerStore = usePartnerStore()
  if (!partnerStore.loaded && partnerStore.status !== 404) {
    await partnerStore.load()
  }
  const authStore = useAuthStore()
  await authStore.profileLoaded()
  const {canAccess, redirectTo} = useAccess(to, from)
  if (canAccess()) {
    next()
    return
  }

  await next(redirectTo())
  return
})

export default router
